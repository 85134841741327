import axios from 'axios'
import { useState, useEffect, ReactElement} from 'react'
import './ContinentSearch.css'

function Continents(props: any) {
    const getLocationsUrl = '/getLocations'

    const [showCountries, setShowCountries] = useState("")

    const shouldShowCountries = (continent: string) =>  {
        if(showCountries !== continent) {
            setShowCountries(continent)
            props.onContinent(continent)
            setShowCities("")
            props.onCountry("")
        }
        else {
            setShowCountries("")
            props.onContinent("")
            setShowCities("")
            props.onCountry("")
        }
    }

    const [showCities, setShowCities] = useState("")

    const shouldShowCities = (country: string) => {
        if(showCities !== country) {
            setShowCities(country)
            props.onCountry(country)
        }
        else {
            setShowCities("")
            props.onCountry("")
        }
    }

    /*
    const locations = [
        {
            continent: "Europe",
            countries: [
                {
                Cities: [
                    'Munich'
                ],
                _id: 'Germany'
            },
            {
                Cities: [
                    'Bristol',
                    'Manchester'
                ],
                _id: 'United Kingdom'
            },
            {
                Cities: [
                    'Lucerne',
                    'Lucerne'
                ],
                _id: 'Switzerland'
            },{
                Cities: [
                    'Paris'
                ],
                _id: 'France'
            },
            {
                Cities: [
                    'Antwerp'
                ],
                _id: 'Belgium'
            },
            {
                Cities: [
                    'Amsterdam',
                    'Harderwijk'
                ],
                _id: 'Netherlands'
            },
            {
                Cities: [
                    'Barcelona'
                ],
                _id: 'Spain'
            },
        ]
        },
        {
            continent: 'Africa',
            countries: [
                {
                    Cities: [
                        'test'
                    ],
                    _id: 'Tanzania'
                }
            ]
        }
    ]*/

    const [locations, setLocations] = useState<any[]>([])
    
    useEffect(() => {
        axios
            .get(getLocationsUrl)
            .then(response => {
                console.log(response)
                setLocations(response.data)
            })
            .catch(error => console.error(`Error: ${error}`));
    }, [])

    return(
    <div className='trip-search-container'>
        <div className="continents-container">
        {locations.map((location, index) => 
        <div className="Continents">
                <ul>
                    {showCountries && showCountries === location?.continent ?
                        <li className='continent-item-selected' onClick={() => shouldShowCountries(location?.continent)}>
                            {location?.continent}
                        </li>
                        : <li className='continent-item' onClick={() => shouldShowCountries(location?.continent)}>
                            {location?.continent}
                        </li>}
                </ul>
            </div>
    )}
    </div>
    <div className="countries-container">
    {locations.map((location, index) => 
                <div className="Countries">
                    {showCountries && showCountries === location?.continent ?
                        <div>
                            <ul>
                                {location?.countries.map((country: any) => <>
                                    {showCities && showCities === country?._id ?
                                        <li className='country-item-selected' onClick={() => shouldShowCities(country?._id)}>
                                            {country?._id}
                                        </li> : <li className='country-item' onClick={() => shouldShowCities(country?._id)}>
                                            {country?._id}
                                        </li>}
                                </>
                                )}
                            </ul>
                        </div>
                        : ""}
                </div>
    )}
    </div>
        </div>
        )
}

export default Continents