import ResponsiveImage from './ResponsiveImage';

function Restaurant(props: any) {
  console.log(props)

  return (
    <div>
        <h3 className='trip-item-title'>{props.Name}</h3>
        <p className='trip-item-body'>{props.Summary}</p>
        <div className='photo-wrapper'>
          {props?.Photos?.map((photo: any) =>
          <ResponsiveImage className='trip-item-photo' loading="lazy" width={'300px'} props={photo}/>)}
        </div>
    </div>
  );
}

export default Restaurant;