import './RecentTrip.css'
import { Link } from "react-router-dom";
import 'moment-timezone';

function RecentTrip(props: any) {
  console.log(props)

  return (
    <div className='recent-post'>
      <img loading="lazy" className="recent-post-photo" src={props.PhotoUrl}></img>
      <div className='recent-post-title'>
        <h1>{props.City}</h1>
        <div> 
            <Link className='blog-link hover-content'
                  to={`/Travel/${props.Description}`}
                  key={props.id}
                  state={{
                        props: {props}
                    }}
                >read more</Link>
        </div>
        </div>
    </div>
  );
}

export default RecentTrip;