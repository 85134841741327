import React from 'react';
import './OurLife.css';
import {Helmet} from 'react-helmet-async'
import {useState, useEffect} from 'react';
import axios from 'axios';
import getRecentPostsURL from '../Functions/getRecentPostsURL';
import { Link } from 'react-router-dom';
import { LifePagesData } from '../DataFiles/LifePagesData';
import PostWrapper from '../Components/PostWrapper';
import '../Pages/Life.css'

function OurLife() {
    const [recentPosts, setRecentPosts] = useState<any[]>([])

    const loadMore = (lastDate: any) => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", lastDate, "Life")
        axios
        .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }

    useEffect(() => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", "", "Life")
        axios
            .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }, [])

  
    if(recentPosts?.length > 0){

        return (
<div>
        <div className='life-header'>
            <div className= 'dutch-bkg-left bkg'></div>
            <div className= 'dutch-bkg-center bkg'></div>
            <div className= 'dutch-bkg-right bkg'></div>
            <h2>Dutch Life</h2>
        </div>

        <div className='life-filter'>
            <p>jump to:</p>
            <ul className='filter-menu'>
                {LifePagesData.map((item:any , index: any) => {
                        return (
                            <li key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
        <div className='life-intro'>
            <p>A collection of musings about day to day life in the Netherlands, as told by two Americans.
            </p>
        </div>

        <div className='life-post-list'>
             {recentPosts?.map((post, index) => 
                 <PostWrapper key={index} recentPost={post} oddeven={index}/>
             )
                 }
        </div>
     
         <div className='load-more'>
             {recentPosts[recentPosts?.length - 1].LastPost ? <h1>LastPost</h1>
             :
             <h5 className='load-more' onClick={() => loadMore(recentPosts[recentPosts?.length - 1].PublishDate)}>load more</h5>}
         </div>

     <div>
                 <Helmet>
                     <title>Our Life in the Netherlands</title>
                     <meta name='description' content='Check out what life looks like for two Americans living in the Netherlands!'></meta>
                 </Helmet>
     </div>
         
</div>

          )
        }
        return null
     }

export default OurLife;