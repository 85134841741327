import React from 'react';
import './UnderConstruction.css';
import { HelmetProvider } from 'react-helmet-async';
import snuggle from '../Images/SnuggleButts.jpg'

function Construction() {
  
  return (
    <HelmetProvider>
    <div className='construction bkg'>
        <h2>This page isn't quite finished, here's a picture of the kittens while you wait!</h2>
    </div>
    </HelmetProvider>
  );
}

export default Construction;