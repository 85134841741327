
function getRecentPostsURL(count: string, recentDate: string, category: string) {
    var base = '/getRecentPosts/'
    var dl = '/'
    if(count === "") {
        count = '5';
    }
    if(recentDate === "") {
        recentDate = new Date().toString()
    }
    if(category === "") {
        category = "all"
    }
    return base.concat(count,dl,recentDate,dl,category)
}

export default getRecentPostsURL