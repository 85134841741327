import React from 'react'
import { Link } from "react-router-dom";
import '../Pages/Life.css'
import ResponsiveImage from './ResponsiveImage';


function RecentPost({recentPost, oddeven}: any) {
    var baseURL = ""
    switch (recentPost?.Category) {
        case 'Life':
            baseURL = 'OurLife';
            break
        case 'Cookbook':
            baseURL = 'Cookbook';
            break;
        case 'DoorstepEssentials':
            baseURL = 'DoorstepEssentials'
            break;
        case 'Guestbook':
            baseURL = 'Guestbook';
            break;
        case 'Letters to Nana': baseURL = 'LettersToNana'
    }


    return (oddeven % 2 == 1 ?
    
        <div className='post-item odd'> 
            <div className='post-item-left-odd'>
                <ResponsiveImage loading="lazy" className='recent-post-photo-fit' props={recentPost.CoverPhoto}></ResponsiveImage>
            </div>
            <div className='post-item-right-odd'>
                <h4>{recentPost.Category}</h4>
                <h2>{recentPost.Title}</h2>
                <p>{recentPost.Summary}</p>
                <h5><Link
                    className='read-more'
                    to={`/${baseURL}/${recentPost.Title}`}
                    key={recentPost.id}
                    state={{
                        props: {recentPost}
                    }}
                    >read more</Link></h5>
            </div>
        </div> : 

        <div className='post-item even'>
            <div className='post-item-left-even'>
                <h4>{recentPost.Category}</h4>
                    <h2>{recentPost.Title}</h2>
                    <p>{recentPost.Summary}</p>
                    <h5><Link
                        className='read-more'
                        to={`/${baseURL}/${recentPost.Title}`}
                        key={recentPost.id}
                        state={{
                            props: {recentPost}
                        }}
                        >read more</Link></h5>
            </div>
            <div className='post-item-right-even read-more'>
                <ResponsiveImage loading="lazy" className='recent-post-photo-fit' props={recentPost.CoverPhoto}></ResponsiveImage>
            </div>
        </div>
)}

export default RecentPost

