import React from 'react';
import {useEffect, useState} from 'react';
import { useLocation, useParams } from "react-router-dom";
import  axios  from 'axios';
import './GuestbookEntry.css';
import { HelmetProvider } from 'react-helmet-async';
import Construction from '../../Components/UnderConstruction';
import Moment from 'react-moment';
import ResponsiveImage from '../../Components/ResponsiveImage';
import PostBody from '../../Components/PostBody';

function GuestbookEntry() {
  const location = useLocation();
  const state:any  = location.state
  const entry = state.props.recentPost

  const baseUrl = `/getPost/${entry._id}`
  const [post, setPost] = useState<any>()
  const [postBody, setBody] = useState<any[]>()


  useEffect(() => {
      if (entry) {
          axios
              .get(baseUrl)
              .then(response => {
                  console.log(response)
                  setPost(response.data.post)
                  setBody(response.data.Body)
              })
             .catch(error => console.error(`Error: ${error}`));
      }
  }, [entry, baseUrl]);

  if(post) {
  return (
          <div className='post'>
              <div>
                <h1>{post?.Title}</h1>
                <h4><Moment className='date' format="MMMM DD, YYYY">{post!.PublishDate}</Moment></h4>
              </div>

              <div>
                    {postBody?.map((item, index) => {
                        return(
                        <PostBody key={index} {...item}/>
                        )
                    })}
                </div>
          </div>
  );
  }
  return null;
}

export default GuestbookEntry;