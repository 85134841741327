import React from 'react'
import axios from 'axios'
import { useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import './Life.css'
import {Helmet} from 'react-helmet-async'
import { LifePagesData } from '../DataFiles/LifePagesData';
import RecentPost from '../Components/RecentPost';
import LifeCoverLeft from '../Images/Frites.jpg'
import LifeCoverCenter from '../Images/Window_kittens.jpeg'
import LifeCoverRight from '../Images/Canal_boats.jpeg'
import getRecentPostsURL from '../Functions/getRecentPostsURL';

//

function Life() {
    //const baseUrl = '/getRecentPosts/5/'

    const [recentPosts, setRecentPosts] = useState<any[]>([])

    const loadMore = (lastDate: any) => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", lastDate, "")
        axios
        .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }

    useEffect(() => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", "", "")
        axios
            .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }, [])

   if(recentPosts?.length > 0){

   return (
    <div>
        
        <div className='life-header'>
        <img className="mobile-cover-photo-home" src={LifeCoverCenter} alt="Floyd and Athena at the window"></img>
            <div className= 'life-bkg-left bkg'></div>
            <div className= 'life-bkg-center bkg'></div>
            <div className= 'life-bkg-right bkg'></div>
            <h2>Life Abroad</h2>
        </div>
        

        <div className='life-filter'>
            <p>jump to:</p>
            <ul className='filter-menu'>
                {LifePagesData.map((item:any , index: any) => {
                        return (
                            <li key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
        
        <div className='life-post-list'>
            {recentPosts?.map((post, index) => 
                <RecentPost key={index} recentPost={post} oddeven={index}/>
            )
                }
        </div>

        <div>
             {recentPosts[recentPosts?.length - 1].LastPost ? <h1>LastPost</h1>
             :
             <h5 className='load-more' onClick={() => loadMore(recentPosts[recentPosts?.length - 1].PublishDate)}>load more</h5>}
         </div>

        <div>
            <Helmet>
                <title>Life in the Netherlands</title>
                <meta name='description' content='Check out what life looks like for two Americans living in the Netherlands!'></meta>
            </Helmet>
        </div>
    
    </div>
     )
   }
   return null
}



export default Life

