import React, {useState, useEffect} from 'react';
import './NavHeader.css';
import { Link } from 'react-router-dom';
import { NavData } from '../DataFiles/NavData';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import Icon from '../Images/FromOurDutchDoorstep_Transparent.png';

function NavHeader() {
  const [clicked, setClicked] = useState(true)

  const showNavbar = () => setClicked(!clicked)

  const [navbarColor, setNavbarColor] = useState(false)
 //navbar scroll changeBackground function
 const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbarColor(true)
    } else {
      setNavbarColor(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  if (navbarColor) {
    return (
        <div className="NavbarItemsOrange">
           <Link className='Dutch-Doorstep' to='/'>
                From Our Dutch Doorstep
            </Link>
    
            <div className="menu-icon" onClick={showNavbar}>
                <div>{clicked ? <FaIcons.FaBars /> : <FaIcons.FaTimes/>}</div>
                </div>
                    <ul className={clicked ? 'nav-menu' : 'nav-menu active'}>
                    {NavData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName} onClick={showNavbar}>
                            <Link to={item.path}>
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    );
                })} 
                    </ul>
        </div>
    )
  }

  return (
    <div className="NavbarItems">
        <div className="home-icon">
          <Link className="navbar-logo-link" to='/'>
            <img src={Icon} alt="Dutch Doorstep Logo" width={60} height={63} className='navbar-logo'/>
          </Link>
        </div>
        <div className="menu-icon" onClick={showNavbar}>
            <div>{clicked ? <FaIcons.FaBars /> : <FaIcons.FaTimes/>}</div>
            </div>
                <ul className={clicked ? 'nav-menu' : 'nav-menu active'}>
                {NavData.map((item, index) => {
                return (
                    <li key={index} className={item.cName} onClick={showNavbar}>
                        <Link to={item.path}>
                            <span>{item.title}</span>
                        </Link>
                    </li>
                );
            })} 
            </ul>
    </div>
  );
}

export default NavHeader;