import React from 'react';
import './App.css';
import NavHeader from './Components/NavHeader';
import './Components/TimelineItem.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Travel from './Pages/Travel';
import Life from './Pages/Life';
import About from './Pages/About';
import Post from './LifePages/LifePosts/Post';
import Trip from './Pages/Trip';
import Cookbook from './LifePages/Cookbook'
import DoorstepEssentials from './LifePages/DoorstepEssentials';
import Guestbook from './LifePages/Guestbook';
import LettersToNana from './LifePages/LettersToNana';
import OurLife from './LifePages/OurLife';
import ScrollToTop from './Components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import Radium from 'radium'
import Recipe from './LifePages/LifePosts/Recipe';
import GuestbookEntry from './LifePages/LifePosts/GuestbookEntry';
import Letter from './LifePages/LifePosts/Letter';

function App() {
  
  return (
    <HelmetProvider>
    <div className="App">
    <Radium.StyleRoot>
      <Router>
        <ScrollToTop>
        <NavHeader></NavHeader>
        <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Travel' element={<Travel />} />
          <Route path='/Travel/:tripId' element={<Trip />} />
          <Route path='/Life' element={<Life />} />
          <Route path='/About' element={<About />} />
          <Route path='/Cookbook' element={<Cookbook />} />
          <Route path='/Cookbook/:postId' element={<Recipe />} />
          <Route path='/DoorstepEssentials' element={<DoorstepEssentials />} />
          <Route path='/Guestbook' element={<Guestbook />} />
          <Route path='/Guestbook/:postId' element={<GuestbookEntry />} />
          <Route path='/LettersToNana' element={<LettersToNana />} />
          <Route path='/LettersToNana/:postId' element={<Letter />} />
          <Route path='/OurLife' element={<OurLife />} />
          <Route path='/OurLife/:postId' element={<Post />} />
        </Routes>
        </div>
        </ScrollToTop>
      </Router>
      <Footer></Footer>
      </Radium.StyleRoot>
    </div>
    </HelmetProvider>
  );
}

export default Radium(App);
