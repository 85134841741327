import {useEffect, useState} from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import  axios  from 'axios';
import './Post.css';
import "react-image-gallery/styles/css/image-gallery.css";
import Moment from 'react-moment';
import PostBody from '../../Components/PostBody';
import ResponsiveImage from '../../Components/ResponsiveImage';

function Post() {
  const location = useLocation();
  const domParams = useParams();
  const postTitle = domParams?.tripId
  const state:any  = location.state

  var recentPost: any;
  console.log(state?.props)
    if(state?.props !=null ) {
        if (state?.props?.recentPost) {
            recentPost = state.props.recentPost
        } else if (state?.props?.nextPost) {
            recentPost = state.props.nextPost
        } else if (state?.props?.previousPost) {
            recentPost = state.props.previousPost
        }
        else {
            recentPost = state?.props?.props
        }
    }

  const [post, setPost] = useState<any>()
  const [postBody, setBody] = useState<any[]>()
  const [nextPost, setNextPost] = useState<any>();
  const [previousPost, setPreviousPost] = useState<any>();

  if(!recentPost?.Title && postTitle) {
    console.log(postTitle)
    axios
        .get(`/getPostByTitle/${postTitle}`)
        .then(response => {
            setPost(response.data)
        })
        .catch(error => console.error(`Error: ${error}`));
    }

    useEffect(() => {
        var baseUrl = `/getPost/${recentPost?._id}`
        console.log(baseUrl)
        if (!baseUrl.includes('undefined')) {
            axios
                .get(baseUrl)
                .then(response => {
                    console.log(response)
                    setPost(response.data.post)
                    setBody(response.data.Body)
                    setNextPost(response.data.next[0])
                    setPreviousPost(response.data.previous[0])
                })
               .catch(error => console.error(`Error: ${error}`));
        }
    }, [recentPost]);

    //FIXME: Fix previous and next links
    if(post) {
    return (
            <div className='post'>
                <div className='title-date-author'>
                    <h1>{post?.Title}</h1>
                    <h4><Moment className='date' format="MMMM DD, YYYY">{post!.PublishDate}</Moment></h4>
                </div>
                <div>
                    {postBody?.map((item, index) => {
                        return(
                        <PostBody key={index} {...item}/>
                        )
                    })}
                </div>
                <div className="navigation-wrapper">
                    <div className='nav-link-left'> 
                        {previousPost?._id !== null ? 
                        <Link to={`/OurLife/${previousPost?.Title}`}
                            onClick={() => setPost(previousPost)}
                            key={previousPost?._id} state={{
                            props: {previousPost}
                            }}>
                            <em>Previous Post:</em><br></br>
                            {previousPost?.Title}
                        </Link>
    : ""}
                    </div>
                    <div className='nav-link-right'>
                        {nextPost?._id !== null ?
                        <Link to={`/OurLife/${nextPost?.Title}`}
                        onClick={() => setPost(nextPost)}
                            key={nextPost?._id} state={{
                            props: {nextPost}
                            }}> 
                            <em>Next Post:</em><br></br>
                            {nextPost?.Title}
                        </Link>
    : "" }
                    </div>
            </div>
            </div>
    );
    }
    return null;
}

export default Post