import React from 'react'
import CoverPhotoMobile from '../Images/homepage_verticaljpg.jpg'
import AboutPhoto from '../Images/HomepageTravelPhoto.jpg'
import { Link, Outlet } from "react-router-dom";
import './Home.css'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Helmet} from 'react-helmet-async'
import RecentTrip from '../Components/RecentTrip';
import ResponsiveImage from '../Components/ResponsiveImage';
import FeaturePost from '../Components/FeaturePost';


function Home(): JSX.Element {
    const baseUrl = `/getRecentTrips/4`
    const [recentTrips, setRecentTrips] = useState<any[]>([])
    const recentPostUrl = '/getRecentPosts/1'
    const [recentPost, setRecentPost] = useState<any>()

    useEffect(() => {
        axios
            .get(baseUrl)
            .then(response => {
                console.log(response)
                setRecentTrips(response.data)
            })
            .catch(error => console.error(`Error: ${error}`));
    }, []);

    useEffect(() => {
        axios
            .get(recentPostUrl)
            .then(response => {
                console.log(response)
                setRecentPost(response?.data[0])
            })
            .catch(error => console.error(`Error: ${error}`));
    }, []);

    return (
        <div className='bkg home-bkg'>
        <div className='overall-container'>
            <Helmet>
                <title>From Our Dutch Doorstep</title>
                <meta name='description' content='We are the Smyths, two American expats living in the Netherlands. We started this blog as a keepsake for us to remember our time abroad, a way to bring our family along with us as we travel, a project to sharpen our website development skills, and just maybe, serve as inspiration for your next trip! '></meta>
            </Helmet>
            <div className="top-container">
                <img className="mobile-cover-photo-home" src={CoverPhotoMobile} alt="mobile cover photo"></img>
                <div className="intro-container"> 
                    <h1 className='title'>From Our Dutch Doorstep</h1>
                    <h4 className="intro">
                        <span style={{textDecoration: 'line-through'}}>
                        Two
                        </span>{' '}Three humans, two cats, and an adventure.
                    </h4> 
                </div>

               
            </div>

        <div className='life-container'>
            <h2>
                Welkom!</h2>
                <p className='welcome-home'>You have stumbled across the little corner of the internet we have deemed our personal, virtual scrapbook. We use this space to document both our travels across Europe, and how we've made a home to return to at our new Dutch Doorstep. </p>

        </div>

        <div className='blog-container'>
                <div className='recent-posts-wrapper'> 
                {recentTrips?.map((trip: any, index: any) =>
                    <RecentTrip key={index} {...trip}></RecentTrip> 
                )}
                </div>
        </div>
        <div className='empty-div'><p>Surprise!</p></div>

        <FeaturePost props={recentPost}/>
 
        <div className='empty-div'><p>Surprise!</p></div>

        <div className='about-container'>
            <div className='about-left'>
                <img className="about-photo" src={AboutPhoto}></img>
            </div> 
            <div className='about-right'>
                <h2 className='title'>It's nice to meet you.</h2>

                <p className='about-home'>We are the Smyths, two American expats living in the Netherlands. We started this blog as a keepsake for us to remember our time abroad, a way to bring our family along with us as we travel, a project to sharpen our website development skills, and just maybe, inspiration for your next trip!</p>

                    <span>
                        <Link className="read-more" to="/About">learn more about us</Link>
                    </span>
                    
            </div>
        </div>
        <div className='empty-div'><p>Surprise!</p></div>

    </div>
    </div>
    );
}

export default Home
