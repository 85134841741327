import * as flagCodes from 'country-flag-icons/react/3x2'

export const FlagData = new Map<string, any>([
    ["Afghanistan", <flagCodes.AF className="tag" title="Afghanistan"/>],
    ["Åland Islands", <flagCodes.AX className="tag" title="Åland Islands"/>],
    ["Albania", <flagCodes.AL className="tag" title="Albania"/>],
    ["Algeria", <flagCodes.DZ className="tag" title="Algeria"/>],
    ["American Samoa", <flagCodes.AS className="tag" title="American Samoa"/>],
    ["Andorra", <flagCodes.AD className="tag" title="Andorra"/>],
    ["Angola", <flagCodes.AO className="tag" title="Angola"/>],
    ["Anguilla", <flagCodes.AI className="tag" title="Anguilla"/>],
    ["Antarctica", <flagCodes.AQ className="tag" title="Antarctica"/>],
    ["Antigua and Barbuda", <flagCodes.AG className="tag" title="Antigua and Barbuda"/>],
    ["Argentina", <flagCodes.AR className="tag" title="Argentina"/>],
    ["Armenia", <flagCodes.AM className="tag" title="Armenia"/>],
    ["Aruba", <flagCodes.AW className="tag" title="Aruba"/>],
    ["Australia", <flagCodes.AU className="tag" title="Australia"/>],
    ["Austria", <flagCodes.AT className="tag" title="Austria"/>],
    ["Azerbaijan", <flagCodes.AZ className="tag" title="Azerbaijan"/>],
    ["Bahamas", <flagCodes.BS className="tag" title="Bahamas"/>],
    ["Bahrain", <flagCodes.BH className="tag" title="Bahrain"/>],
    ["Bangladesh", <flagCodes.BD className="tag" title="Bangladesh"/>],
    ["Barbados", <flagCodes.BB className="tag" title="Barbados"/>],
    ["Belarus", <flagCodes.BY className="tag" title="Belarus"/>],
    ["Belgium", <flagCodes.BE className="tag" title="Belgium"/>],
    ["Belize", <flagCodes.BZ className="tag" title="Belize"/>],
    ["Benin", <flagCodes.BJ className="tag" title="Benin"/>],
    ["Bermuda", <flagCodes.BM className="tag" title="Bermuda"/>],
    ["Bhutan", <flagCodes.BT className="tag" title="Bhutan"/>],
    ["Bolivia (Plurinational State of)", <flagCodes.BO className="tag" title="Bolivia (Plurinational State of)"/>],
    ["Bonaire, Sint Eustatius and Saba[c]", <flagCodes.BQ className="tag" title="Bonaire, Sint Eustatius and Saba[c]"/>],
    ["Bosnia and Herzegovina", <flagCodes.BA className="tag" title="Bosnia and Herzegovina"/>],
    ["Botswana", <flagCodes.BW className="tag" title="Botswana"/>],
    ["Bouvet Island", <flagCodes.BV className="tag" title="Bouvet Island"/>],
    ["Brazil", <flagCodes.BR className="tag" title="Brazil"/>],
    ["British Indian Ocean Territory", <flagCodes.IO className="tag" title="British Indian Ocean Territory"/>],
    ["Brunei Darussalam", <flagCodes.BN className="tag" title="Brunei Darussalam"/>],
    ["Bulgaria", <flagCodes.BG className="tag" title="Bulgaria"/>],
    ["Burkina Faso", <flagCodes.BF className="tag" title="Burkina Faso"/>],
    ["Burundi", <flagCodes.BI className="tag" title="Burundi"/>],
    ["Cabo Verde", <flagCodes.CV className="tag" title="Cabo Verde"/>],
    ["Cambodia", <flagCodes.KH className="tag" title="Cambodia"/>],
    ["Cameroon", <flagCodes.CM className="tag" title="Cameroon"/>],
    ["Canada", <flagCodes.CA className="tag" title="Canada"/>],
    ["Cayman Islands", <flagCodes.KY className="tag" title="Cayman Islands"/>],
    ["Central African Republic", <flagCodes.CF className="tag" title="Central African Republic"/>],
    ["Chad", <flagCodes.TD className="tag" title="Chad"/>],
    ["Chile", <flagCodes.CL className="tag" title="Chile"/>],
    ["China[b]", <flagCodes.CN className="tag" title="China[b]"/>],
    ["Christmas Island", <flagCodes.CX className="tag" title="Christmas Island"/>],
    ["Cocos (Keeling) Islands", <flagCodes.CC className="tag" title="Cocos (Keeling) Islands"/>],
    ["Colombia", <flagCodes.CO className="tag" title="Colombia"/>],
    ["Comoros", <flagCodes.KM className="tag" title="Comoros"/>],
    ["Congo", <flagCodes.CG className="tag" title="Congo"/>],
    ["Congo, Democratic Republic of the", <flagCodes.CD className="tag" title="Congo, Democratic Republic of the"/>],
    ["Cook Islands", <flagCodes.CK className="tag" title="Cook Islands"/>],
    ["Costa Rica", <flagCodes.CR className="tag" title="Costa Rica"/>],
    ["Côte d'Ivoire", <flagCodes.CI className="tag" title="Côte d'Ivoire"/>],
    ["Croatia", <flagCodes.HR className="tag" title="Croatia"/>],
    ["Cuba", <flagCodes.CU className="tag" title="Cuba"/>],
    ["Curaçao", <flagCodes.CW className="tag" title="Curaçao"/>],
    ["Cyprus[b]", <flagCodes.CY className="tag" title="Cyprus[b]"/>],
    ["Czechia", <flagCodes.CZ className="tag" title="Czechia"/>],
    ["Denmark", <flagCodes.DK className="tag" title="Denmark"/>],
    ["Djibouti", <flagCodes.DJ className="tag" title="Djibouti"/>],
    ["Dominica", <flagCodes.DM className="tag" title="Dominica"/>],
    ["Dominican Republic", <flagCodes.DO className="tag" title="Dominican Republic"/>],
    ["Ecuador", <flagCodes.EC className="tag" title="Ecuador"/>],
    ["Egypt", <flagCodes.EG className="tag" title="Egypt"/>],
    ["El Salvador", <flagCodes.SV className="tag" title="El Salvador"/>],
    ["Equatorial Guinea", <flagCodes.GQ className="tag" title="Equatorial Guinea"/>],
    ["Eritrea", <flagCodes.ER className="tag" title="Eritrea"/>],
    ["Estonia", <flagCodes.EE className="tag" title="Estonia"/>],
    ["Eswatini", <flagCodes.SZ className="tag" title="Eswatini"/>],
    ["Ethiopia", <flagCodes.ET className="tag" title="Ethiopia"/>],
    ["Falkland Islands (Malvinas)[b]", <flagCodes.FK className="tag" title="Falkland Islands (Malvinas)[b]"/>],
    ["Faroe Islands", <flagCodes.FO className="tag" title="Faroe Islands"/>],
    ["Fiji", <flagCodes.FJ className="tag" title="Fiji"/>],
    ["Finland", <flagCodes.FI className="tag" title="Finland"/>],
    ["France", <flagCodes.FR className="tag" title="France"/>],
    ["French Guiana", <flagCodes.GF className="tag" title="French Guiana"/>],
    ["French Polynesia", <flagCodes.PF className="tag" title="French Polynesia"/>],
    ["French Southern Territories", <flagCodes.TF className="tag" title="French Southern Territories"/>],
    ["Gabon", <flagCodes.GA className="tag" title="Gabon"/>],
    ["Gambia", <flagCodes.GM className="tag" title="Gambia"/>],
    ["Georgia", <flagCodes.GE className="tag" title="Georgia"/>],
    ["Germany", <flagCodes.DE className="tag" title="Germany"/>],
    ["Ghana", <flagCodes.GH className="tag" title="Ghana"/>],
    ["Gibraltar", <flagCodes.GI className="tag" title="Gibraltar"/>],
    ["Greece", <flagCodes.GR className="tag" title="Greece"/>],
    ["Greenland", <flagCodes.GL className="tag" title="Greenland"/>],
    ["Grenada", <flagCodes.GD className="tag" title="Grenada"/>],
    ["Guadeloupe", <flagCodes.GP className="tag" title="Guadeloupe"/>],
    ["Guam", <flagCodes.GU className="tag" title="Guam"/>],
    ["Guatemala", <flagCodes.GT className="tag" title="Guatemala"/>],
    ["Guernsey", <flagCodes.GG className="tag" title="Guernsey"/>],
    ["Guinea", <flagCodes.GN className="tag" title="Guinea"/>],
    ["Guinea-Bissau", <flagCodes.GW className="tag" title="Guinea-Bissau"/>],
    ["Guyana", <flagCodes.GY className="tag" title="Guyana"/>],
    ["Haiti", <flagCodes.HT className="tag" title="Haiti"/>],
    ["Heard Island and McDonald Islands", <flagCodes.HM className="tag" title="Heard Island and McDonald Islands"/>],
    ["Holy See", <flagCodes.VA className="tag" title="Holy See"/>],
    ["Honduras", <flagCodes.HN className="tag" title="Honduras"/>],
    ["Hong Kong", <flagCodes.HK className="tag" title="Hong Kong"/>],
    ["Hungary", <flagCodes.HU className="tag" title="Hungary"/>],
    ["Iceland", <flagCodes.IS className="tag" title="Iceland"/>],
    ["India", <flagCodes.IN className="tag" title="India"/>],
    ["Indonesia", <flagCodes.ID className="tag" title="Indonesia"/>],
    ["Iran (Islamic Republic of)", <flagCodes.IR className="tag" title="Iran (Islamic Republic of)"/>],
    ["Iraq", <flagCodes.IQ className="tag" title="Iraq"/>],
    ["Ireland", <flagCodes.IE className="tag" title="Ireland"/>],
    ["Isle of Man", <flagCodes.IM className="tag" title="Isle of Man"/>],
    ["Israel", <flagCodes.IL className="tag" title="Israel"/>],
    ["Italy", <flagCodes.IT className="tag" title="Italy"/>],
    ["Jamaica", <flagCodes.JM className="tag" title="Jamaica"/>],
    ["Japan", <flagCodes.JP className="tag" title="Japan"/>],
    ["Jersey", <flagCodes.JE className="tag" title="Jersey"/>],
    ["Jordan", <flagCodes.JO className="tag" title="Jordan"/>],
    ["Kazakhstan", <flagCodes.KZ className="tag" title="Kazakhstan"/>],
    ["Kenya", <flagCodes.KE className="tag" title="Kenya"/>],
    ["Kiribati", <flagCodes.KI className="tag" title="Kiribati"/>],
    ["Korea (Democratic People's Republic of)", <flagCodes.KP className="tag" title="Korea (Democratic People's Republic of)"/>],
    ["Korea, Republic of", <flagCodes.KR className="tag" title="Korea, Republic of"/>],
    ["Kuwait", <flagCodes.KW className="tag" title="Kuwait"/>],
    ["Kyrgyzstan", <flagCodes.KG className="tag" title="Kyrgyzstan"/>],
    ["Lao People's Democratic Republic", <flagCodes.LA className="tag" title="Lao People's Democratic Republic"/>],
    ["Latvia", <flagCodes.LV className="tag" title="Latvia"/>],
    ["Lebanon", <flagCodes.LB className="tag" title="Lebanon"/>],
    ["Lesotho", <flagCodes.LS className="tag" title="Lesotho"/>],
    ["Liberia", <flagCodes.LR className="tag" title="Liberia"/>],
    ["Libya", <flagCodes.LY className="tag" title="Libya"/>],
    ["Liechtenstein", <flagCodes.LI className="tag" title="Liechtenstein"/>],
    ["Lithuania", <flagCodes.LT className="tag" title="Lithuania"/>],
    ["Luxembourg", <flagCodes.LU className="tag" title="Luxembourg"/>],
    ["Macao", <flagCodes.MO className="tag" title="Macao"/>],
    ["Madagascar", <flagCodes.MG className="tag" title="Madagascar"/>],
    ["Malawi", <flagCodes.MW className="tag" title="Malawi"/>],
    ["Malaysia", <flagCodes.MY className="tag" title="Malaysia"/>],
    ["Maldives", <flagCodes.MV className="tag" title="Maldives"/>],
    ["Mali", <flagCodes.ML className="tag" title="Mali"/>],
    ["Malta", <flagCodes.MT className="tag" title="Malta"/>],
    ["Marshall Islands", <flagCodes.MH className="tag" title="Marshall Islands"/>],
    ["Martinique", <flagCodes.MQ className="tag" title="Martinique"/>],
    ["Mauritania", <flagCodes.MR className="tag" title="Mauritania"/>],
    ["Mauritius", <flagCodes.MU className="tag" title="Mauritius"/>],
    ["Mayotte", <flagCodes.YT className="tag" title="Mayotte"/>],
    ["Mexico", <flagCodes.MX className="tag" title="Mexico"/>],
    ["Micronesia (Federated States of)", <flagCodes.FM className="tag" title="Micronesia (Federated States of)"/>],
    ["Moldova, Republic of", <flagCodes.MD className="tag" title="Moldova, Republic of"/>],
    ["Monaco", <flagCodes.MC className="tag" title="Monaco"/>],
    ["Mongolia", <flagCodes.MN className="tag" title="Mongolia"/>],
    ["Montenegro", <flagCodes.ME className="tag" title="Montenegro"/>],
    ["Montserrat", <flagCodes.MS className="tag" title="Montserrat"/>],
    ["Morocco", <flagCodes.MA className="tag" title="Morocco"/>],
    ["Mozambique", <flagCodes.MZ className="tag" title="Mozambique"/>],
    ["Myanmar", <flagCodes.MM className="tag" title="Myanmar"/>],
    ["Namibia", <flagCodes.NA className="tag" title="Namibia"/>],
    ["Nauru", <flagCodes.NR className="tag" title="Nauru"/>],
    ["Nepal", <flagCodes.NP className="tag" title="Nepal"/>],
    ["Netherlands", <flagCodes.NL className="tag" title="Netherlands"/>],
    ["New Caledonia", <flagCodes.NC className="tag" title="New Caledonia"/>],
    ["New Zealand", <flagCodes.NZ className="tag" title="New Zealand"/>],
    ["Nicaragua", <flagCodes.NI className="tag" title="Nicaragua"/>],
    ["Niger", <flagCodes.NE className="tag" title="Niger"/>],
    ["Nigeria", <flagCodes.NG className="tag" title="Nigeria"/>],
    ["Niue", <flagCodes.NU className="tag" title="Niue"/>],
    ["Norfolk Island", <flagCodes.NF className="tag" title="Norfolk Island"/>],
    ["North Macedonia", <flagCodes.MK className="tag" title="North Macedonia"/>],
    ["Northern Mariana Islands", <flagCodes.MP className="tag" title="Northern Mariana Islands"/>],
    ["Norway", <flagCodes.NO className="tag" title="Norway"/>],
    ["Oman", <flagCodes.OM className="tag" title="Oman"/>],
    ["Pakistan", <flagCodes.PK className="tag" title="Pakistan"/>],
    ["Palau", <flagCodes.PW className="tag" title="Palau"/>],
    ["Palestine, State of[b]", <flagCodes.PS className="tag" title="Palestine, State of[b]"/>],
    ["Panama", <flagCodes.PA className="tag" title="Panama"/>],
    ["Papua New Guinea", <flagCodes.PG className="tag" title="Papua New Guinea"/>],
    ["Paraguay", <flagCodes.PY className="tag" title="Paraguay"/>],
    ["Peru", <flagCodes.PE className="tag" title="Peru"/>],
    ["Philippines", <flagCodes.PH className="tag" title="Philippines"/>],
    ["Pitcairn", <flagCodes.PN className="tag" title="Pitcairn"/>],
    ["Poland", <flagCodes.PL className="tag" title="Poland"/>],
    ["Portugal", <flagCodes.PT className="tag" title="Portugal"/>],
    ["Puerto Rico", <flagCodes.PR className="tag" title="Puerto Rico"/>],
    ["Qatar", <flagCodes.QA className="tag" title="Qatar"/>],
    ["Réunion", <flagCodes.RE className="tag" title="Réunion"/>],
    ["Romania", <flagCodes.RO className="tag" title="Romania"/>],
    ["Russian Federation", <flagCodes.RU className="tag" title="Russian Federation"/>],
    ["Rwanda", <flagCodes.RW className="tag" title="Rwanda"/>],
    ["Saint Barthélemy", <flagCodes.BL className="tag" title="Saint Barthélemy"/>],
    ["Saint Helena, Ascension and Tristan da Cunha[d]", <flagCodes.SH className="tag" title="Saint Helena, Ascension and Tristan da Cunha[d]"/>],
    ["Saint Kitts and Nevis", <flagCodes.KN className="tag" title="Saint Kitts and Nevis"/>],
    ["Saint Lucia", <flagCodes.LC className="tag" title="Saint Lucia"/>],
    ["Saint Martin (French part)", <flagCodes.MF className="tag" title="Saint Martin (French part)"/>],
    ["Saint Pierre and Miquelon", <flagCodes.PM className="tag" title="Saint Pierre and Miquelon"/>],
    ["Saint Vincent and the Grenadines", <flagCodes.VC className="tag" title="Saint Vincent and the Grenadines"/>],
    ["Samoa", <flagCodes.WS className="tag" title="Samoa"/>],
    ["San Marino", <flagCodes.SM className="tag" title="San Marino"/>],
    ["Sao Tome and Principe", <flagCodes.ST className="tag" title="Sao Tome and Principe"/>],
    ["Saudi Arabia", <flagCodes.SA className="tag" title="Saudi Arabia"/>],
    ["Senegal", <flagCodes.SN className="tag" title="Senegal"/>],
    ["Serbia", <flagCodes.RS className="tag" title="Serbia"/>],
    ["Seychelles", <flagCodes.SC className="tag" title="Seychelles"/>],
    ["Sierra Leone", <flagCodes.SL className="tag" title="Sierra Leone"/>],
    ["Singapore", <flagCodes.SG className="tag" title="Singapore"/>],
    ["Sint Maarten (Dutch part)", <flagCodes.SX className="tag" title="Sint Maarten (Dutch part)"/>],
    ["Slovakia", <flagCodes.SK className="tag" title="Slovakia"/>],
    ["Slovenia", <flagCodes.SI className="tag" title="Slovenia"/>],
    ["Solomon Islands", <flagCodes.SB className="tag" title="Solomon Islands"/>],
    ["Somalia", <flagCodes.SO className="tag" title="Somalia"/>],
    ["South Africa", <flagCodes.ZA className="tag" title="South Africa"/>],
    ["South Georgia and the South Sandwich Islands", <flagCodes.GS className="tag" title="South Georgia and the South Sandwich Islands"/>],
    ["South Sudan", <flagCodes.SS className="tag" title="South Sudan"/>],
    ["Spain", <flagCodes.ES className="tag" title="Spain"/>],
    ["Sri Lanka", <flagCodes.LK className="tag" title="Sri Lanka"/>],
    ["Sudan", <flagCodes.SD className="tag" title="Sudan"/>],
    ["Suriname", <flagCodes.SR className="tag" title="Suriname"/>],
    ["Svalbard and Jan Mayen[e]", <flagCodes.SJ className="tag" title="Svalbard and Jan Mayen[e]"/>],
    ["Sweden", <flagCodes.SE className="tag" title="Sweden"/>],
    ["Switzerland", <flagCodes.CH className="tag" title="Switzerland"/>],
    ["Syrian Arab Republic", <flagCodes.SY className="tag" title="Syrian Arab Republic"/>],
    ["Taiwan, Province of China[b]", <flagCodes.TW className="tag" title="Taiwan, Province of China[b]"/>],
    ["Tajikistan", <flagCodes.TJ className="tag" title="Tajikistan"/>],
    ["Tanzania, United Republic of", <flagCodes.TZ className="tag" title="Tanzania, United Republic of"/>],
    ["Thailand", <flagCodes.TH className="tag" title="Thailand"/>],
    ["Timor-Leste", <flagCodes.TL className="tag" title="Timor-Leste"/>],
    ["Togo", <flagCodes.TG className="tag" title="Togo"/>],
    ["Tokelau", <flagCodes.TK className="tag" title="Tokelau"/>],
    ["Tonga", <flagCodes.TO className="tag" title="Tonga"/>],
    ["Trinidad and Tobago", <flagCodes.TT className="tag" title="Trinidad and Tobago"/>],
    ["Tunisia", <flagCodes.TN className="tag" title="Tunisia"/>],
    ["Türkiye", <flagCodes.TR className="tag" title="Türkiye"/>],
    ["Turkmenistan", <flagCodes.TM className="tag" title="Turkmenistan"/>],
    ["Turks and Caicos Islands", <flagCodes.TC className="tag" title="Turks and Caicos Islands"/>],
    ["Tuvalu", <flagCodes.TV className="tag" title="Tuvalu"/>],
    ["Uganda", <flagCodes.UG className="tag" title="Uganda"/>],
    ["Ukraine", <flagCodes.UA className="tag" title="Ukraine"/>],
    ["United Arab Emirates", <flagCodes.AE className="tag" title="United Arab Emirates"/>],
    ["United Kingdom", <flagCodes.GB className="tag" title="United Kingdom of Great Britain and Northern Ireland"/>],
    ["United States of America", <flagCodes.US className="tag" title="United States of America"/>],
    ["United States Minor Outlying Islands[f]", <flagCodes.UM className="tag" title="United States Minor Outlying Islands[f]"/>],
    ["Uruguay", <flagCodes.UY className="tag" title="Uruguay"/>],
    ["Uzbekistan", <flagCodes.UZ className="tag" title="Uzbekistan"/>],
    ["Vanuatu", <flagCodes.VU className="tag" title="Vanuatu"/>],
    ["Venezuela (Bolivarian Republic of)", <flagCodes.VE className="tag" title="Venezuela (Bolivarian Republic of)"/>],
    ["Viet Nam", <flagCodes.VN className="tag" title="Viet Nam"/>],
    ["Virgin Islands (British)", <flagCodes.VG className="tag" title="Virgin Islands (British)"/>],
    ["Virgin Islands (U.S.)", <flagCodes.VI className="tag" title="Virgin Islands (U.S.)"/>],
    ["Wallis and Futuna", <flagCodes.WF className="tag" title="Wallis and Futuna"/>],
    ["Western Sahara[b]", <flagCodes.EH className="tag" title="Western Sahara[b]"/>],
    ["Yemen", <flagCodes.YE className="tag" title="Yemen"/>],
    ["Zambia", <flagCodes.ZM className="tag" title="Zambia"/>],
    ["Zimbabwe", <flagCodes.ZW className="tag" title="Zimbabwe"/>]
]);