import {useState} from 'react'
import * as FaIcons from "react-icons/fa";
import * as RxIcons from "react-icons/rx"
import './Footer.css'

function Footer() {
    const [open, setOpen] = useState(false)
    const openEnvelope = () => setOpen(true)
    const closeEnvelope = () => setOpen(false)


    return (
       <div className='Footer'> 
           <a className='social-link' href='https://www.instagram.com/dutchdoorstep' aria-label="Dutch Doorstep Instagram" target="_blank" rel="noreferrer"><FaIcons.FaInstagram/></a>
           <a className='social-link' href='https://www.pinterest.com/dutchdoorstep' aria-label="Dutch Doorstep Pinterest" target="_blank" rel="noreferrer"><FaIcons.FaPinterest/></a>

                {open ? 
                    <a onMouseLeave={() => closeEnvelope()} className='social-link' aria-label="Dutch Doorstep Email" href='mailto:hello@dutchdoorstep.com'  rel="noreferrer"><RxIcons.RxEnvelopeOpen/></a>
                    : <a onMouseEnter={() => openEnvelope()} className='social-link' aria-label="Dutch Doorstep Email" href='mailto:hello@dutchdoorstep.com'  rel="noreferrer"><RxIcons.RxEnvelopeClosed/></a>}
        </div> 
    );
}

export default Footer