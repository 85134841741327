import './TimelineItem.css';
import Moment from 'react-moment';
import { FlagData } from '../DataFiles/FlagData';
import { Link, Outlet } from "react-router-dom";
import ResponsiveImage from './ResponsiveImage';


function TimelineItem(props: any) {
    console.log(props)

    return(
        <div className="timeline-item">
                <div className="timeline-photo-summary">
                    {props.RespImg ? <ResponsiveImage className="timeline-image"
                            props={props.RespImg}
                            coverPhoto={true}
                            alt={props.RespImg?.altTag}
                            width="100%"
                        /> : ""}
                    <div className='hover-content'>
                        <div className='flag-image'>{FlagData.get(props.Country)} </div>
                        <p>{props.Summary}</p>
                        <div>
                        <Link 
                            to={`/Travel/${props.Description}`}
                            key={props.id}
                            state={{
                                props: {props}
                            }}
                        >read more</Link>
                        <Outlet />
                        </div>
                    </div>
                </div>
                <div className='timeline-item-title'>
                    <h2>{props.Description}</h2>
                    <Moment format="MMMM YYYY">{props.EventDate}</Moment> 
                  
                </div>
        </div>
    );
}
            
export default TimelineItem;