export const NavData = [
    {
        title: 'Life',
        path: '/Life',
        cName: 'nav-links'
    },
    {
        title: 'Travel',
        path: '/Travel',
        cName: 'nav-links'
    },
    {
        title: 'About',
        path: '/About',
        cName: 'nav-links'
    },
]