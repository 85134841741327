import React from 'react';
import '../Pages/Home.css'
import ResponsiveImage from './ResponsiveImage';
import { Link} from 'react-router-dom';

function FeaturePost(props: any) {
  const recentPost = props?.props;
  var baseURL = ""
    switch (recentPost?.Category) {
        case 'Life':
            baseURL = 'OurLife';
            break
        case 'Cookbook':
            baseURL = 'Cookbook';
            break;
        case 'DoorstepEssentials':
            baseURL = 'DoorstepEssentials'
            break;
        case 'Guestbook':
            baseURL = 'Guestbook';
            break;
        case 'Letters to Nana': baseURL = 'LettersToNana'
    }

  return (
    <div className='life-recent-post'>
            <ResponsiveImage className="life-cover-photo" loading="lazy" props={recentPost?.CoverPhoto}></ResponsiveImage>
            <div className='life-recent-post-text'>
                <h4>{recentPost?.Category}</h4>
                <h2>{recentPost?.Title}</h2>
                <p>{recentPost?.Summary}</p>
                <h5><Link
                        className='life-recent-post-link read-more'
                        to={`/${baseURL}/${recentPost?.Title}`}
                        key={recentPost?.id}
                        state={{
                            props: {recentPost}
                        }}
                        >read more</Link></h5>
            </div>
        </div>
  );
}

export default FeaturePost;