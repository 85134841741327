export const LifePagesData = [
    {
        title: 'Our Life',
        path: '/OurLife',
    },
    {
        title: 'Our Guestbook',
        path: '/Guestbook',
    },
    {
        title: 'Our Cookbook',
        path: '/Cookbook',
    },
    {
        title: 'Letters To Nana',
        path: '/LettersToNana',
    },
    {
        title: 'Doorstep Essentials',
        path: '/DoorstepEssentials',
    },
]