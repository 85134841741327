import './Travel.css';
import TimelineItem from '../Components/TimelineItem';
import axios from 'axios'
import { useState, useEffect, ReactElement} from 'react'
import { Helmet } from 'react-helmet-async';
import Continents from '../Components/ContinentSearch';
import 'moment-timezone';
import { Link, Outlet } from "react-router-dom";
import Radium from 'radium'
import ResponsiveImage from '../Components/ResponsiveImage';

function Travel() {
        const [showMap, setShowMap] = useState(false)

        const shouldShowMap = () =>  {
            setShowMap(!showMap)
        }

        const [continent, setContinent] = useState("");

        const continentHandler = (continent: string) => {
            setContinent(continent);
        }

        const [country, setCountry] = useState("");

        const countryHandler = (country: string) => {
            setCountry(country);
        }

        const baseUrl = `/getEvents/${country}`

        const recentTripUrl = `/getRecentTrips/1`

        const [recentTrip, setRecentTrip] = useState<any>()
       
        const [timelineData, setTimelineData] = useState<any[]>([])
    
        useEffect(() => {
            axios
                .get(baseUrl)
                .then(response => {
                    console.log(response)
                    setTimelineData(response.data)
                })
                .catch(error => console.error(`Error: ${error}`));
        }, [country])

        useEffect(() => {
            axios
                .get(recentTripUrl)
                .then(response => {
                    console.log(response)
                    setRecentTrip(response.data[0])
                })
                .catch(error => console.error(`Error: ${error}`));
        }, [])

        const defaultProps = {
            center: {
              lat: 10.99835602,
              lng: 77.01502627
            },
            zoom: 11
          };

        const bkgStyle = {
            backgroundImage: `url(${recentTrip?.CoverPhoto})`,
            '@media only screen and (max-width: 767px)': {
                backgroundImage: 'none',
              },
        }

          if (recentTrip) {
          return (
            <Radium.StyleRoot>
            <div className='bkg' style={bkgStyle}> 
            <Helmet>
                    <title>What have we been up to?</title>
                    <meta name='description' content='Check out our recent travels!'></meta>
            </Helmet>
                <div className="item3">
                    <img className='mobile-cover-photo'
                            src={recentTrip?.CoverPhoto}
                            alt="{recentTrip?.Description}"
                            width="100%"
                        />
                    <h5>Lately...</h5> 
                    <div className='rp-title'>
                        <h1>{recentTrip?.City}</h1>
                        <Link
                        className='recent-post-link'
                        to={`/Travel/${recentTrip.Description}`}
                        key={recentTrip.id}
                        state={{
                            props: {recentTrip}
                        }}
                        >read more</Link>
                    </div>
                </div>
                <div className="item4">
                    <h5 className="country-title">filter trips: </h5>
                    <Continents onContinent={continentHandler} onCountry={countryHandler}/>
                    
                </div>  
                <div className="item5">
                    {timelineData.map((tlEvents, index) =>
                    <TimelineItem className='timeline-item' key={index} {...tlEvents}></TimelineItem> 
                    )}
                </div>
                </div>
                </Radium.StyleRoot>
            
          );
        }
                    else {return <></>}

   
}

export default Travel