import ResponsiveImage from './ResponsiveImage';

function PostBody(props: any) {
  return (
    <div>
        <h3 className='trip-item-title'>{props?.DisplayTitle}</h3>
        <p className='trip-item-body'>{props.Text}</p>
        <div className='photo-wrapper'>
            {props?.Photos?.map((photo: any) =>
            <ResponsiveImage className='trip-item-photo' loading="lazy" width={'300px'} props={photo}/>)}
        </div>
    </div>
  );
}

export default PostBody;