import React from 'react';
import './LettersToNana.css';
import {Helmet} from 'react-helmet-async'
import PostWrapper from '../Components/PostWrapper';
import {useState, useEffect} from 'react';
import axios from 'axios';
import getRecentPostsURL from '../Functions/getRecentPostsURL';
import { Link } from 'react-router-dom';
import { LifePagesData } from '../DataFiles/LifePagesData';

function LettersToNana() {
    const [recentPosts, setRecentPosts] = useState<any[]>([])

    const loadMore = (lastDate: any) => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", lastDate, "Letters to Nana")
        axios
        .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }
  
    useEffect(() => {
        var blogPostsData: any[] = [];
        var url = getRecentPostsURL("", "", "Letters to Nana")
        axios
            .get(url)
            .then(response => {
                console.log(response)
                blogPostsData = response.data;
                setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
                console.log(blogPostsData)
            })
            .catch((error: any) => console.error(`Error: ${error}`));
    }, [])
  
  
    if(recentPosts?.length > 0){
  
        return (
<div>
       <div className='life-header'>
            <div className= 'letters-bkg-left bkg'></div>
            <div className= 'letters-bkg-center bkg'></div>
            <div className= 'letters-bkg-right bkg'></div>
            <h2>Letters to Nana</h2>
        </div>

        <div className='life-filter'>
            <p>jump to:</p>
            <ul className='filter-menu'>
                {LifePagesData.map((item:any , index: any) => {
                        return (
                            <li key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
        <div className='life-intro'>
      <p> Welcome to a peek into the letters I send my Nana. She is your typical sugar sweet grandmother who likes to regularly check in on her grandchildren. She and my grandfather have done their fair share of travelling in their lives, and it has been so fun to trade travel stories and learn more about their trips as Brendan and I made our way across the globe. 
        I give her a call every couple of weeks, but I started sending her and my grandpa an email (at their request, to see pictures!) once a month-ish to keep them updated on our travels, hear about upcoming trips and visitors, and get an update on life in the Netherlands. Since these have essentially become a journal, we decided to keep them on the blog for easy viewing and reminiscing in the future.
      </p>
    </div>               

    <div className='life-post-list'>
             {recentPosts?.map((post, index) => 
                 <PostWrapper key={index} recentPost={post} oddeven={index}/>
             )
                 }
    </div>
     
    <div className='load-more read-more'>
             {recentPosts[recentPosts?.length - 1].LastPost ? <h1>LastPost</h1>
             :
             <button onClick={() => loadMore(recentPosts[recentPosts?.length - 1].PublishDate)}>load more</button>}
    </div>
    <div>
                 <Helmet>
                     <title>Letters to Nana</title>
                     <meta name='description' content='Check out the letters we send across the Atlantic back to our Nana in America!'></meta>
                 </Helmet>
    </div>
         
</div>
          )
        }
        return null
     }
  
  export default LettersToNana;