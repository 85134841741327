import React from 'react';
import '../Pages/Home.css'
import ResponsiveImage from './ResponsiveImage';
import { Link} from 'react-router-dom';
import FeaturePost from './FeaturePost';
import RecentPost from './RecentPost';

function PostWrapper({recentPost, oddeven}: any) {
  if(oddeven == 0) {
    return <FeaturePost props={recentPost}/>
  }
  
  return (
    <RecentPost recentPost={recentPost} oddeven={oddeven}/>
  );
}

export default PostWrapper;