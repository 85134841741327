import React from 'react';
import './Cookbook.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link, Outlet } from "react-router-dom";
import RecentPost from '../Components/RecentPost';
import { LifePagesData } from '../DataFiles/LifePagesData';
import getRecentPostsURL from '../Functions/getRecentPostsURL';
import PostWrapper from '../Components/PostWrapper';

function Cookbook() {
  const [recentPosts, setRecentPosts] = useState<any[]>([])

  const loadMore = (lastDate: any) => {
      var blogPostsData: any[] = [];
      var url = getRecentPostsURL("", lastDate, "Cookbook")
      axios
      .get(url)
          .then(response => {
              console.log(response)
              blogPostsData = response.data;
              setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
              console.log(blogPostsData)
          })
          .catch((error: any) => console.error(`Error: ${error}`));
  }

  useEffect(() => {
      var blogPostsData: any[] = [];
      var url = getRecentPostsURL("", "", "Cookbook")
      axios
          .get(url)
          .then(response => {
              console.log(response)
              blogPostsData = response.data;
              setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
              console.log(blogPostsData)
          })
          .catch((error: any) => console.error(`Error: ${error}`));
  }, [])
  
  if(recentPosts?.length > 0){
  return  ( 
<div>
    <div className='life-header'>
            <div className= 'cook-bkg-left bkg'></div>
            <div className= 'cook-bkg-center bkg'></div>
            <div className= 'cook-bkg-right bkg'></div>
            <h2>Our Cookbook</h2>
        </div>

        <div className='life-filter'>
            <p>jump to:</p>
            <ul className='filter-menu'>
                {LifePagesData.map((item:any , index: any) => {
                        return (
                            <li key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>

    <div className='life-intro'>
      <p> Okay, so this may be a little misleading, because none of these recipes are my own. Instead, these are all other's recipes (shoutout to Lindsay at Pinch of Yum and Gaby at What's Gaby Cooking for always coming through on dinner inspo, I love you both dearly), and are meals that we eat <em>all the time</em>. It's nice to have these all in one spot for easy reference, and is the closest thing I've got to a recipe book while we're abroad. I have made adjustments based on the grocery items we have access to in our Dutch supermarkets-- limited baking ingredients (looking at you chocolate chips), a lack of premade enchilada/buffalo/ranch sauce, spice selections, etc. I have enjoyed learning about typical Dutch food, though I can confirm, food is not romanticized here. For foodies like us, this difference adds to the adventure of finding great restaurants around Den Bosch, and making great food in our own kitchen! 
      </p>
    </div>

    <div className='life-post-list'>
             {recentPosts?.map((post, index) => 
                 <PostWrapper key={index} recentPost={post} oddeven={index}/>
             )
                 }
    </div>
     
    <div className='load-more read-more'>
           {recentPosts[recentPosts?.length - 1]?.LastPost ? <h1>LastPost</h1>
           :
           <button onClick={() => loadMore(recentPosts[recentPosts?.length - 1].PublishDate)}>load more</button>}
       </div>
    <div>
                 <Helmet>
                     <title>From Our Dutch Doorstep Cookbook</title>
                     <meta name='description' content='Check out some of our favorite recipes to cook when we are home!'></meta>
                 </Helmet>
    </div>
</div>

  )}
  return null
}

export default Cookbook;