import React from 'react'
import { AboutUs } from "../DataFiles/AboutUs"
import './About.css'
import AboutPhoto from '../Images/Lovebirds.jpeg'
import Brendan from '../Images/Brendan_Beer.jpeg';
import Jennie from '../Images/Jennie.jpeg';
import Floyd from '../Images/Floyd_NapTime.jpeg';
import Athena from '../Images/Athena_Yawn.jpeg';
import Holden from '../Images/Holden.jpeg';
import {Helmet} from 'react-helmet-async';


function About() {
    return (
    <div> 
        <Helmet>
                <title>About the Smyths</title>
                <meta name='description' content='We are the Smyths, two American expats living in the Netherlands with our two cats. We met in Madison, Wisconsin in 2017, and since then have enjoyed making new memories together while traveling around the world together.'></meta>
            </Helmet>
        <div className='about-us-container'>
            <h2 className='title'>Hi there. We are the Smyths.</h2>
            <div className='about-us-wrapper'>
                <img className="about-us-left" src={AboutPhoto}></img>
                    
                <div className='about-us-right'>
                        <p className='about-us-text'>{AboutUs.get("AboutUs")}</p>
                </div>
            </div>
        </div>

        <div className='squad-container'>
            <h2 className='title'>Meet the Squad</h2>
            <div className="squad-wrapper">
                <div className= 'squad-member'>
                    <h3>Jennie</h3>
                    <div className= 'squad-member-wrapper'>
                        <img loading="lazy" className="squad-member-photo" src={Jennie}></img>
                        <p>Jennie is an adventurous spirit whose willingness to try new things has led to many exciting experiences, including relocating to a country she had never visited. She loves a good bike ride, spending time in the kitchen, and of course, snuggling with the kitty cats. </p>
                    </div>
                </div>

                <div className= 'squad-member'>
                    <h3>Brendan</h3>
                    <div className= 'squad-member-wrapper'>
                        <img loading="lazy" className="squad-member-photo" src={Brendan}></img>
                        <p>The technical brains behind the blog, and the reason this blog came to be! Brendan loves trying out local beers, going for a run to explore a new city, and sharpening a skill (like website development) any chance he gets. Without his new job or creative daydream, our Dutch Doorstep (both literal and figurative) would not exist!</p>
                    </div>
                </div>
                
                <div className= 'squad-member'>
                    <h3>Holden</h3>
                    <div className= 'squad-member-wrapper'>
                    <img loading="lazy" className="squad-member-photo" src={Holden}></img>
                        <p>Holden Graham joined the squad on November 14th. We are so excited to have him as a part of the family and travel squad and can't wait to experience our future trips from a new perspective! </p>
                    </div>
                                        
                </div>
                <div className= 'squad-member'>
                    <h3>Athena</h3>
                    <div className= 'squad-member-wrapper'>
                    <img loading="lazy" className="squad-member-photo" src={Athena}></img>
                        <p>Sweet Baby Squirrel is a snuggly cat who speaks her mind. She loves all things food, and never turns down a good cuddle. </p>
                    </div>
                    
                </div>
                <div className= 'squad-member'>
                    <h3>Floyd</h3>
                    <div className= 'squad-member-wrapper'>
                    <img loading="lazy" className="squad-member-photo" src={Floyd}></img>
                        <p>Floyd is an explorer at heart, and lover of all things garbage. He's always down for a snooze in the sun or a good belly scratch. </p>
                    </div>
                                        
                </div>
            </div>
        </div>
    </div>
    );
}

export default About