import React from 'react';
import './Guestbook.css';
import {Helmet} from 'react-helmet-async'
import RecentPost from '../Components/RecentPost';
import {useState, useEffect} from 'react';
import axios from 'axios';
import getRecentPostsURL from '../Functions/getRecentPostsURL';
import { Link } from 'react-router-dom';
import { LifePagesData } from '../DataFiles/LifePagesData';
import PostWrapper from '../Components/PostWrapper';

function Guestbook() {
  const [recentPosts, setRecentPosts] = useState<any[]>([])

  const loadMore = (lastDate: any) => {
      var blogPostsData: any[] = [];
      var url = getRecentPostsURL("", lastDate, "Guestbook")
      axios
      .get(url)
          .then(response => {
              console.log(response)
              blogPostsData = response.data;
              setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
              console.log(blogPostsData)
          })
          .catch((error: any) => console.error(`Error: ${error}`));
  }

  useEffect(() => {
      var blogPostsData: any[] = [];
      var url = getRecentPostsURL("", "", "Guestbook")
      axios
          .get(url)
          .then(response => {
              console.log(response)
              blogPostsData = response.data;
              setRecentPosts(recentPosts => [...recentPosts, ...blogPostsData])
              console.log(blogPostsData)
          })
          .catch((error: any) => console.error(`Error: ${error}`));
  }, [])


  if(recentPosts?.length > 0){

      return (
    <div>
       <div className='life-header'>
            <div className= 'guest-bkg-left bkg'></div>
            <div className= 'guest-bkg-center bkg'></div>
            <div className= 'guest-bkg-right bkg'></div>
            <h2>Our Guestbook</h2>
        </div>

        <div className='life-filter'>
            <p>jump to:</p>
            <ul className='filter-menu'>
                {LifePagesData.map((item:any , index: any) => {
                        return (
                            <li key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
        <div className='life-intro'>
            <p>Although this blog is largely a collection of our travels abroad, it is also about making a home in the Netherlands! And what is a home without good company. Our guestbook is a reminder of the friends and family that have travelled to stay with us, and let us show them a glimpse of our real Dutch Doorstep. 
            </p>
        </div>

        <div className='life-post-list'>
             {recentPosts?.map((post, index) => 
                 <PostWrapper key={index} recentPost={post} oddeven={index}/>
             )
                 }
        </div>
   
       <div className='load-more read-more'>
           {recentPosts[recentPosts?.length - 1]?.LastPost ? <h1>LastPost</h1>
           :
           <button onClick={() => loadMore(recentPosts[recentPosts?.length - 1].PublishDate)}>load more</button>}
       </div>
   <div>
               <Helmet>
                   <title>From Our Dutch Doorstep Guestbook</title>
                   <meta name='description' content='Check out what we are up to when our friends and family visit us in the Netherlands!'></meta>
               </Helmet>
   </div>
       
</div>
        )
      }
      return null
   }

export default Guestbook;