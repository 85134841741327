import React from 'react';
import { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import './Recipe.css';
import { HelmetProvider } from 'react-helmet-async';
import Moment from 'react-moment';
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import ResponsiveImage from '../../Components/ResponsiveImage';

function Recipe() {
  const location = useLocation();
  const state:any  = location.state
  const recipe = state.props.recentPost

  const baseUrl = `/getPost/${recipe._id}`
  const [post, setPost] = useState<any>()
  const [coverPhoto, setCoverPhoto] = useState<any>();
  const jumpToInstructions = () => {
    const element = document.getElementById('instructions');
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }

  useEffect(() => {
    console.log(recipe)
    if (recipe) {
        axios
            .get(baseUrl)
            .then(response => {
                console.log(response)
                setPost(response.data.post)
                setCoverPhoto(response.data.CoverPhoto)
            })
           .catch(error => console.error(`Error: ${error}`));
    }
}, []);

if(post) {
  
  return (
    <HelmetProvider>
    <div>
      <div className= 'title-date-author recipe-header'>
        <h1>{post!.Title}</h1>
        <h4><Moment className='date' format="MMMM DD YYYY">{post!.PublishDate}</Moment></h4>
        <h6 className='jump-to' onClick={jumpToInstructions}>Jump to Recipe Instuctions</h6>
      </div>
      <div className='recipe-intro'>
      <ResponsiveImage className="post-img"
                            props={coverPhoto}
                            coverPhoto={true}
                            alt=""
                            width="100%"
                        />
          {post?.Body?.map((body: any, index: any) =>
          <div className='recipe-summary'><p>{body.Text}</p></div>
          )}
      </div>
      <div className='recipe-card-title' id='instructions'>
        <h2>{post!.Title}</h2>
        <div className='recipe-card'>
          <div className='ingredients'>
            <h5>ingredients</h5>
            <ul>
            {post?.Ingredients?.map((ingredient: any, index: any) =>
              <li>{ingredient.Amount} {ingredient.Title} 
              <br></br><p className='note'>{ingredient.Note}</p></li>
            )}
            </ul>
          </div>
        
            <div className='instructions'>
              <h5>instructions</h5>
              <ol>
              {post?.Instructions?.map((instructions: any, index: any) =>
              <li>{instructions}</li>
              )}
              </ol>
            </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
  );}
  return null
}
export default Recipe;
