import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./Fonts/JosefinSans-VariableFont_wght.ttf"
import "./Fonts/Alice-Regular.ttf"
import "./Fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf"
import "./Fonts/PlayfairDisplay-VariableFont_wght.ttf"
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ReactGA.initialize("G-EEKG449B22");
ReactGA.send("pageview");

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
