import axios from 'axios';
import { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { useLocation, useParams } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import Restaurant from '../Components/Restaurant'
import './Trip.css'
import Lodging from '../Components/Lodging';
import Activity from '../Components/Activity';
import Icon from '../Images/FromOurDutchDoorstep.png';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import ResponsiveImage from '../Components/ResponsiveImage';
import { STATUS_CODES } from 'http';
import Radium from 'radium'
import ReactMarkdown from 'react-markdown';
import CustomLink from '../Components/CustomLink';

function Trip() {
    const location = useLocation();
    const domParams = useParams();
    const tripName = domParams?.tripId
    const state:any  = location.state
    var recentTrip: any;
    if(state?.props !=null ) {
        if (state?.props?.recentTrip) {
            recentTrip = state.props.recentTrip
        } else if (state?.props?.nextTrip) {
            recentTrip = state.props.nextTrip
        } else if (state?.props?.previousTrip) {
            recentTrip = state.props.previousTrip
        }
        else {
            recentTrip = state?.props?.props
        }
    }
  
    const [trip, setTrip] = useState<ITrip>({} as ITrip);
    const [tripText, setTripText] = useState<any>();
    const [coverPhoto, setCoverPhoto] = useState<any>();
    const [restaurants, setRestaurants] = useState<any[]>([]);
    const [lodging, setLodging] = useState<any[]>([]);
    const [activities, setActivities] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [mobileImages, setMobileImages] = useState<any[]>([]);
    const [nextTrip, setNextTrip] = useState<any>();
    const [previousTrip, setPreviousTrip] = useState<any>();
    var [recentTrip, setRecentTrip] = useState<any>();

    function imagesHelper(photos: any[]) {
        setImages(photos)
        var finalImages = [];
        for(var i=0; i < photos.length; i++) {
            if (photos[i]?.signedUrl) {
                finalImages.push({original: photos[i].signedUrl});
            }
        }
        setMobileImages(finalImages)
    }

    function getRecentTrip(url: any) {
        axios
            .get(url)
            .then(response => {
                setRecentTrip(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }

//

/*const trip = {
  Travelers:["Brendan"],
  City:"Lucerne",
  Country:"Switzerland",
  "Reason":"Work",
  Description:"Support trip at hospital in Lucerne",
  location:{type:"Point",coordinates:[{"$numberDecimal":"8.30635"},{"$numberDecimal":"47.05048"}]},
  Lodging:[{Name:"Renaissance Lucerne",Rating:{"$numberInt":"4"},Price:"915.80 CHF",Address:{Street:"Pilatusstrasse 15",City:"Lucerne",Country:"Switzerland",Zip:"6002"},Phone:"+41 41 2268787",Summary:"This hotel was located just a few minutes walk from the train station. It was also only a few blocks from the river walk which has tons of shops and restaurants. The room had a king-sized bed and was of average size. Breakfast was a typical restaurant buffet available every morning."}],
  Restaurants:[{Name:"Pastarazzi",Rating:{"$numberDouble":"2.5"},Review:"Small restaurant with many different types of ravioli. You order at the counter where you can mix and match the different ravioli. I had a basil and cheese ravioli, as well as a meat ravioli. I chose a pesto sauce and spicy sausage as a topping. The portions were average size. Overall a decent meal that was quick and easy after a long day of traveling."},{Name:"Schiffrestaurant Wilhelm Tell",Rating:{"$numberDouble":"4.5"},Review:"The Wilhelm Tell restaurant is on a boat docked on Lake Lucerne. It serves typical Swiss fair which beautiful views of the lake and mountains if you get the right table. I ordered corn-fed chicken with asparagus which was delicious. For dessert, I had chocolate mousse with tiramisu ice cream which completed a delicious meal. The restaurant might be a tourist trap but both local Swiss and Americans enjoyed the experience together."},{Name:"Restaurant Helvetia",Rating:{"$numberDouble":"3.5"},Review:"Restaurant Helvetia serves local Swiss food. Most, if not all, ingredients are locally sourced which is called out repeatedly on their menu. I ate white fish from the lake with potatoes which was tasty, if a little underwhelming. The atmosphere was good and service was high quality."},{"Name":"Fondue House Du Pont","Rating":{"$numberDouble":"3.5"},"Review":"Fondue House Du Pont is located just off the river and seems to be a spot that many tourists find. The Raclette and meat fondue that my group had were both very tasty. The amount of food was very generous so we ended up skipping out on chocolate fondue. The group I was with enjoyed espressos, chocolates, and aperitifs for several hours after finishing the fondue which seems like a common Swiss tradition."}],
  Arrival:"2022-05-17T05:00:00.000Z",Category:"trip",Departure:"2022-05-22T05:00:00.000Z",EventDate:"2022-05-17T22:00:00.000Z",
  Summary:"My first support trip to a European hospital spoiled me. Lucerne is a beautiful city with picturesque views that made this trip one of my favorite 'work' trips ever.",
  Activities:[{Name: "Activity 1",Summary: "Stuff about the activity"}]}
    const tripText = "This was my first opportunity to visit a European hospital. This was my first opportunity to visit a European hospital This was my first opportunity to visit a European hospital"
*/
    interface ITrip {
        Description: String,
        EventDate: Date,
        Arrival: Date,
        Country: String,
        Departure: Date,
        Travelers: [String],
        City: String,
        Reason: String,
        Start: Boolean,
        location: {
          type: String,
          coordinates: [Number, Number]
        },
        Lodging: 
          [
            {
              Name: String,
              Rating: Number,
              Price: String,
              Address: {
                Street: String,
                City: String,
                Country: String,
                Zip: String
              },
              Phone: String,
              Summary: String
            }
          ]
        ,
        Restaurants: 
          [
            {
              Name: String,
              Rating: Number,
              Review: String,
              Photos: String
            }
          ]
          ,
        Activities:
          [
            {
              Name: String,
              Summary: String
            }
          ]
        Text: String,
        Summary: string,
        _id: String,
        PhotoFiles: 
        [
            {
                signedUrl: string
            }
        ]
    }

    if(!recentTrip?.Description && tripName) {
        console.log(tripName)
        axios
            .get(`/getTripByName/${tripName}`)
            .then(response => {
                setRecentTrip(response.data)
            })
            .catch(error => console.error(`Error: ${error}`));
    }

    useEffect(() => {
       var baseUrl = `/getTrip/${recentTrip?._id}`;
       console.log(baseUrl)
        if (!baseUrl.includes('undefined')) {
            axios
                .get(baseUrl)
                .then(response => {
                    console.log(response)
                    setTrip(response.data.post)
                    setRestaurants(response.data.Restaurants)
                    setActivities(response.data.Activities)
                    setLodging(response.data.Lodging)
                    response.data.photos.shift()
                    setCoverPhoto(response.data.CoverPhoto)
                    imagesHelper(response.data.photos)
                    setTripText(response.data.text)
                    setNextTrip(response.data.next[0])
                    setPreviousTrip(response.data.previous[0])
                })
               .catch(error => console.error(`Error: ${error}`));
        }
    }, [recentTrip]);

    const bkgStyle = {
        backgroundImage: `url(${coverPhoto?.altURLs?.fullJPG})`,
        '@media only screen and (max-width: 767px)': {
            backgroundImage: 'none',
          },
    }

    if(recentTrip?._id == trip?._id) {
        return (
            <Radium.StyleRoot>
            <div className='bkg' style={bkgStyle}> 
                <Helmet>
                    <title>{trip?.Description}</title>
                    <meta name='description' content={trip?.Summary}></meta>
                </Helmet>
            <div className='mobile-cover-photo'>
                <ResponsiveImage className="post-img"
                            props={coverPhoto}
                            coverPhoto={true}
                            alt=""
                            width="100%"
                        />
            </div>   

        <div className='body'>
            <div className='post-header'>
               <div className='trip-title'>
                  <h1 className='title'>{trip!.Description}</h1>
                  <div className='date-container'>
                    <Moment className='date' format="LL">{trip!.Arrival}</Moment>
                    <div className='date'>-</div>
                    <Moment className='date' format="LL">{trip!.Departure}</Moment>
                  </div> 
                </div>
            </div>
            
        
                
        <div className='text'>
                    <ReactMarkdown
                    children={tripText}
                    components={{
                      p: ({node, ...props}) => <span className='test-span' {...props} />,
                      pre: ({node, ...props}) => <span className='test-span' {...props} />,
                      code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <Link
                            to={String(children).replace(/\n$/, '').substring(0,String(children).indexOf("^"))}
                            onClick={() => getRecentTrip(String(children).replace(/\n$/, '').substring(0,String(children).indexOf("^")))}
                            className="embedded-link"
                          >
                          {String(children).substring(String(children).indexOf("^")+1,String(children).length-1)}  
                          </Link>
                        ) : (
                          <code {...props} className={className}>
                            {children}
                          </code>
                        )
                      }
                    }}
                    />
                    <img src={Icon} alt="Dutch Doorstep Logo" width={40} height={42} className='dutch-doorstep'/>
                {restaurants.length != 0 ? 
                <div>
                    <h2 className='trip-header'>Where We Ate</h2>
                    {restaurants?.map((restaurant, index) =>
                        <Restaurant key={index} {...restaurant}></Restaurant>
                    )}
                </div>
                : "" }
                {lodging.length != 0 ? 
                <div>
                    <h2 className='trip-header'>Where We Stayed</h2>
                    {lodging?.map((lodging, index) =>
                        <Lodging key={index} {...lodging}></Lodging>
                    )}
                </div>
    : ""}
                {activities.length != 0 ? 
                <div>
                    <h2 className='trip-header'>What We Did</h2>
                    {activities?.map((activity, index) =>
                        <Activity key={index} {...activity}></Activity>
                    )}
                </div>

                
    : ""}
            </div>
            <div className='photo-gallery'>
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1200: 4, 1500: 5}}
            >
                <Masonry gutter="10px">
                    {images.map((image, i) => (
                        <ResponsiveImage className="post-img"
                            loading="lazy"
                            props={image?.singleResponsiveImage}
                            style={{width: "100%", display: "block", margin: "0px"}}
                            width="100%"
                            alt=""
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            </div>

            <div className='image-gallery'>
                <ImageGallery items={mobileImages}/>
            </div>
                
    
            <div className="navigation-wrapper">
            {previousTrip?._id !== undefined ? 
                <div className='nav-link-left'> 
                
                    <Link to={`/Travel/${previousTrip?.Description}`}
                    onClick={() => setRecentTrip(previousTrip)}
                    key={previousTrip?.id} state={{
                        props: {previousTrip}
                    }}>
                        <em>Previous Post:</em><br></br>
                        {previousTrip?.City}
                    </Link>
                
    
                </div>
                : ""}
                {nextTrip?._id !== undefined ?
                <div className='nav-link-right'>
                    
                    <Link to={`/Travel/${nextTrip?.Description}`} 
                    onClick={() => setRecentTrip(nextTrip)}
                    key={nextTrip?.id} state={{
                        props: {nextTrip}
                    }}> <em>Next Post:</em><br></br>
                        {nextTrip?.City}
         
                    </Link>
    
                </div>
                : "" }
            </div>
       </div>
    </div> 
    </Radium.StyleRoot>
        )
                    }
        return null;
}

export default Trip
