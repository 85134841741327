import React from 'react';
import './Letter.css';
import { HelmetProvider } from 'react-helmet-async';
import Construction from '../../Components/UnderConstruction';

function Letter() {
  
  return (
    <HelmetProvider>
    <div>
        <Construction/>
    </div>
    </HelmetProvider>
  );
}

export default Letter;