import 'moment-timezone';

function ResponsiveImage(props: any) {
  var unResponsiveImg = false;
  var thisAltTag = "";

  if(props?.props?.altTag != "") {
    thisAltTag = props?.props?.altTag
  }

  for(var property in props?.props?.altURLs) {
    if (props?.props?.altURLs[property] === "") {
      unResponsiveImg = true;
    }
  }
  if (unResponsiveImg) {
    return(<img width={props?.width} alt={thisAltTag} className={props?.className} src={props?.props?.altURLs?.fullJPG} />)
  }

  if(props?.coverPhoto) {
    return (
        <picture className={props?.className} style={props?.style}>
         <source
           type="image/webp"
           srcSet={`${props?.props?.altURLs?.phoneWeb} 320w, ${props?.props?.altURLs?.smallWeb} 480w, ${props?.props?.altURLs?.fullWeb} 1280w`}
           sizes="(max-width: 450px) 90vw, (max-width: 768px) 768px, 100%"
         />
         <source
           type="image/jpeg"
           srcSet={`${props?.props?.altURLs?.phoneJPG} 320w, ${props?.props?.altURLs?.smallJPG} 480w, ${props?.props?.altURLs?.fullJPG} 1280w`}
           sizes="(max-width: 450px) 90vw, (max-width: 768px) 768px, 100%"
         />
         <img width={props?.width} src={props?.props?.altURLs?.fullJPG} alt={thisAltTag}  />
       </picture>
     );
  }

  return (
    <picture className={props?.className} style={props?.style}>
     <source
       type="image/webp"
       srcSet={`${props?.props?.altURLs?.phoneWeb} 320w, ${props?.props?.altURLs?.smallWeb} 480w, ${props?.props?.altURLs?.fullWeb} 1280w`}
       sizes="(max-width: 450px) 90vw, (max-width: 768px) 768px, 400px"
     />
     <source
       type="image/jpeg"
       srcSet={`${props?.props?.altURLs?.phoneJPG} 320w, ${props?.props?.altURLs?.smallJPG} 480w, ${props?.props?.altURLs?.fullJPG} 1280w`}
       sizes="(max-width: 450px) 90vw, (max-width: 768px) 768px, 400px"
     />
     <img width={props?.width} src={props?.props?.altURLs?.fullJPG} alt={thisAltTag}/>
   </picture>
 );
}

export default ResponsiveImage;